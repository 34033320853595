import { useQuery } from '@tanstack/react-query';
import classes from './Details.module.css'
import { useParams } from 'react-router-dom';
import { fetchProduct } from "../../../utils/http/productService";
import { Alert, Box, CircularProgress } from '@mui/material';
import { useState } from 'react';

export default function Details(){
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const {
        data: product,
        isLoading: loadingProduct,
        isError: errorProduct,
        error: productError,
      } = useQuery({
        queryKey: ["product", id],
        queryFn: () => fetchProduct(id),
        staleTime: 5000,
        select: (data) => data.product || [],
      });

      if (loadingProduct) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        );
      }
    
      if (errorProduct) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="30vh"
          >
            <Alert
              severity="error"
              style={{ minWidth: "50vw" }}
              onClose={() => setErrorMessage(null)}
            >
              {productError?.message ||
                "Erreur lors de la récupération des données."}
            </Alert>
          </Box>
        );
      }

    return <>
        <div className={classes.product_container}>
            <h1 className={classes.product_title}>{product.name}</h1>
            <div className={classes.category_buttons}>
            <button className={classes.category_button}>Décoration</button>
            <button className={classes.category_button}>
                Collection de Aid
            </button>
            <button className={classes.category_button}>
                Pour la maison
            </button>
            <button className={classes.category_button}>
                World of les chandelles
            </button>
            <button className={classes.category_button}>Décoration</button>
            <button className={classes.category_button}>
                Collection de Aid
            </button>
            <button className={classes.category_button}>
                World of les chandelles
            </button>
            </div>
            <div className={classes.product_description}>
            <p>
                {product.description}
            </p>
            </div>
            <ul className={classes.product_details}>
            <li>
                <strong>Item Weight:</strong> 600 Grams
            </li>
            <li>
                <strong>Elegant glass container</strong>
            </li>
            <li>
                <strong>100% Parfumé</strong>
            </li>
            <li>
                <strong>Model:</strong> 5'9"/175cm and enjoys using
            </li>
            <li>
                <strong>Natural Soy Blend Wax Candle</strong>
            </li>
            </ul>
        </div>
    </>
}