import React, { useEffect, useState, memo } from "react";
import classes from "./Avis.module.css";
import SelectArrow from "./../../../assets/icons/SelectArrow.svg";
import PopupAvis from "./PopupAvis";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchReviews } from "../../../utils/http/reviewService";
import { useParams } from "react-router-dom";
import { Alert, Box, CircularProgress } from "@mui/material";
import { renderStars } from "./../../ui/renderStars";

const ReviewCard = memo(({ review }) => {
  return (
    <div className={classes.avis_card}>
      <div>
        <div className={classes.stars}>
          {renderStars(review.rating)}
        </div>
        <div className={classes.name}>{review.user.name}</div>
      </div>
      <div className={classes.message}>{review.message}</div>
      <div className={classes.posted}>
        Posted : 
        {new Date(review.created_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
    </div>
  );
});

const sortingMethods = {
  "Les plus récents": (reviews) =>
    [...(reviews || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
  "Les plus anciens": (reviews) =>
    [...(reviews || [])].sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
  "Les mieux notés": (reviews) => [...(reviews || [])].sort((a, b) => b.rating - a.rating),
  "Les moins bien notés": (reviews) => [...(reviews || [])].sort((a, b) => a.rating - b.rating),
};

export default function Avis({ visibleCards, setVisibleCards }) {
  const { id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Les mieux notés");
  const [sortedReviews, setSortedReviews] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    data: reviews,
    isLoading: loadingReviews,
    isError: errorReviews,
    error: reviewsError,
  } = useQuery({
    queryKey: ["reviews", id],
    queryFn: () => fetchReviews(id),
    staleTime: 5000,
    select: (data) => data.reviews || [],
  });

  useEffect(() => {
    if (reviews) {
      setSortedReviews(sortingMethods["Les mieux notés"](reviews));
    }
  }, [reviews]);

  const visibleReviews = sortedReviews ? sortedReviews.slice(0, visibleCards) : [];
  const isReviews = reviews && reviews.length > 0;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectOption = (option) => {
    if (!sortingMethods[option]) return;
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setSortedReviews(sortingMethods[option](reviews));
  };

  const handleLoadMore = () => {
    setVisibleCards((prevState) => prevState + 6);
  };

  if (loadingReviews) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (errorReviews) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert
          severity="error"
          style={{ minWidth: "50vw" }}
          onClose={() => setErrorMessage(null)}
        >
          {reviewsError?.message || "Erreur lors de la récupération des données."}
        </Alert>
      </Box>
    );
  }

  return (
    <div className={classes.avis_container}>
      <div className={classes.avis_header}>
        <h1>
          All Reviews <span className={classes.number}>({reviews ? reviews.length : 0})</span>
        </h1>
        <div className={classes.review_section}>
          {isReviews && (
            <div className={classes.dropdown}>
              <button
                className={classes.dropdown_button}
                onClick={toggleDropdown}
              >
                <p>{selectedOption}</p>
                <div className={classes.arrow}>
                  <img
                    src={SelectArrow}
                    className={classes.select_arrow_icon}
                    alt="Select Arrow"
                  />
                </div>
              </button>
              {isDropdownOpen && (
                <div
                  className={`${classes.dropdown_content} ${
                    isDropdownOpen ? classes.open : ""
                  }`}
                >
                  <p onClick={() => selectOption("Les plus récents")} className={classes.option}>
                    Les plus récents
                  </p>
                  <p onClick={() => selectOption("Les plus anciens")} className={classes.option}>
                    Les plus anciens
                  </p>
                  <p onClick={() => selectOption("Les mieux notés")} className={classes.option}>
                    Les mieux notés
                  </p>
                  <p onClick={() => selectOption("Les moins bien notés")} className={classes.option}>
                    Les moins bien notés
                  </p>
                </div>
              )}
            </div>
          )}
          {isLoggedIn && (
            <button
              className={classes.write_review_button}
              onClick={() => setIsPopupOpen((prevState) => !prevState)}
            >
              Écrire un avis
            </button>
          )}
        </div>
      </div>
      <PopupAvis isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
      <div className={classes.avis_content}>
        {isReviews ? (
          <div className={classes.cards}>
            {visibleReviews.map((review, index) => (
              <ReviewCard key={index} review={review} />
            ))}
          </div>
        ) : (
          <p className={classes.no_reviews}>Aucun avis disponible pour ce produit.</p>
        )}
        <div className={classes.btn_container}>
          {visibleCards < reviews.length && (
            <button
              className={classes.load_more_btn}
              onClick={handleLoadMore}
            >
              Charger plus d'avis...
            </button>
          )}
        </div>
      </div>
    </div>
  );
}