import React from "react";
import { Grid } from "@mui/material";
import classes from "./FrequentlyAskedQuestions.module.css";
import FaqImg from "./../../assets/images/faq/image-1.png";
import Title from "./../ui/Title";
import ControlledAccordions from "./ControlledAccordions";

const AnswerToQuestion = ({ faqs }) => {
  return (
    <section>
      <Title title="RÉPONSE À CHAQUE QUESTION" />
      <div className={classes.faq_container}>
        <div className={classes.faq_content}>
          <ControlledAccordions faqs={faqs} />
        </div>
        <div className={classes.faq_image_container} style={{ justifyContent: "flex-end" }}>
          <img className={classes.faq_image} src={FaqImg} alt="Faq" />
        </div>
      </div>
    </section>
  );
};

export default AnswerToQuestion;
