import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteAsync,
  loadFavorites,
  removeFavoriteAsync,
} from "../../redux/slices/favoritesSlice";
// import { Star, StarHalf, StarBorder } from "@mui/icons-material";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import FavoriteBorderSharpIcon from "@mui/icons-material/FavoriteBorderSharp";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import CircularProgress from "@mui/material/CircularProgress";
import Bag from "./../../assets/icons/Shopping Bag.svg";
import classes from "./Product.module.css";
import { Link, useNavigate } from "react-router-dom";
import ColoredCircle from "./ColoredCircle";

const Product = ({
  productId,
  imgSrc,
  productName,
  productPrice,
  productReview,
  productColors,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state) => state.favorites);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [isLoading, setIsLoading] = useState(false);

  const isFavorite = favorites.some(
    (item) => Number(item.product_id) === Number(productId)
  );

  const handleFavoriteToggle = async () => {
    if (isLoggedIn) {
      setIsLoading(true);
      try {
        if (isFavorite) {
          await dispatch(removeFavoriteAsync(productId));
        } else {
          await dispatch(addFavoriteAsync(productId));
        }
        await dispatch(loadFavorites());
      } finally {
        setIsLoading(false);
      }
    } else {
      navigate("/login");
    }
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <StarRateRoundedIcon 
              key={`full-${index}`} 
              className={classes.star}
              style={{ fill: "#FFD700" }}
            />
          ))}
        {halfStars === 1 && <StarHalfRoundedIcon key="half"  className={classes.star} />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <StarBorderRoundedIcon key={`empty-${index}`} className={classes.star} />
          ))}
      </>
    );
  };

  const rating = parseFloat(productReview) || 0;

  return (
    <div className={classes.product_container}>
      <div className={classes.image_container}>
        <img src={imgSrc} alt={productName} className={classes.product_img} />
        <Link
          to={`/product-details/${productId}`}
          className={classes.add_to_cart_button}
        >
          + Ajouter au panier
        </Link>
        <div className={classes.favorite_icon} onClick={handleFavoriteToggle}>
          {isLoading ? (
            <CircularProgress size={24} style={{ color: "red" }} />
          ) : isFavorite ? (
            <FavoriteSharpIcon style={{ color: "red", fontSize: 32 }} />
          ) : (
            <FavoriteBorderSharpIcon style={{ color: "black", fontSize: 32 }} />
          )}
        </div>
        <div className={classes.cart_icon_mobile}>
          <Link to={`/product-details/${productId}`}>
            <img src={Bag} alt="Shopping Bag" />
          </Link>
        </div>
      </div>
      <div className={classes.product_content}>
        <h3 className={classes.product_name}>{productName}</h3>
        <div className={classes.product_info}>
          <p className={classes.product_price}>{productPrice} Dhs</p>
          <div className={classes.product_review}>{renderStars(rating)}</div>
        </div>
        <div className={classes.product_colors}>
          {productColors?.map((color, index) => (
            <ColoredCircle key={index} bgColor={color} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
