import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";

export const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const decimalPart = rating % 1;
    const halfStar = decimalPart >= 0.25 && decimalPart <= 0.75;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
  
    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <StarRateRoundedIcon key={`full-${index}`} />
        ))}
        {decimalPart > 0.75 && (
          <StarRateRoundedIcon style={{ opacity: 0.5 }} />
        )}
        {halfStar && <StarHalfRoundedIcon />}
        {[...Array(emptyStars)].map((_, index) => (
          <StarBorderRoundedIcon key={`empty-${index}`} />
        ))}
      </>
    );
};