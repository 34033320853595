import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import classes from "./Footer.module.css";
import Instagram from "./../../../assets/icons/instagram.svg";
import Facebook from "./../../../assets/icons/facebook.svg";
import Tiktok from "./../../../assets/icons/tiktok.svg";
import WhatsApp from "./../../../assets/icons/whatsapp.svg";
import CashlessPayment from "./../../../assets/icons/Cashless payment.svg";
import ExchangeSvg from "./../../../assets/icons/Exchange.svg";
import ExpressDelivery from "./../../../assets/icons/Express delivery.svg";
import ScrollUp from "../../ui/ScrollUp";

function Footer() {
  return (
    <footer className={classes.footer_container}>
      <Grid container spacing={3} className={classes.footer_content}>
        <Grid item xs={12} sm={4} className={classes.footer_sections}>
          <div className={classes.footer_section}>
            <Typography variant="h6" className={classes.footer_heading}>
              Aide
            </Typography>
            <Typography className={classes.footer_text}>
              Centre d'aide, Litiges et rapports, Protection des acheteurs,
              Signaler une violation des DPI, Informations réglementées,
              Conformité à l'intégrité
            </Typography>
          </div>
          <div style={{ marginTop: '1rem' }} className={classes.footer_section}>
            <Typography variant="h6" className={classes.footer_heading}>
              Parcourir par catégorie
            </Typography>
            <Typography className={classes.footer_text}>
              Tous les populaires, Produit, Promotion, Prix bas, Excellent
              rapport qualité-prix, Commentaires, Blog, Portail vendeur, BLACK
              FRIDAY, Assistant Les chandelles
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.footer_section}>
          <Typography variant="h6" className={classes.footer_heading}>
            Rester connecté
          </Typography>
          <Box className={classes.social_icons}>
            <Link
              to="https://www.instagram.com/les_chandelles_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className={classes.social_icon}
              />
            </Link>
            <Link
              to="https://facebook.com/people/les-chandelles/61553683991616/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Instagram}
                alt="Instagram"
                className={classes.social_icon}
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/leschandelles/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={WhatsApp}
                alt="Whatsapp"
                className={classes.social_icon}
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/leschandelles/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Tiktok}
                alt="Tiktok"
                className={classes.social_icon}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.features} sx={{ display: { xs: 'none', sm: 'block' }}}>
            <Typography variant="h6" className={classes.footer_heading}>
              Features
            </Typography>
            <div className={classes.feature}>
              <img
                src={ExpressDelivery}
                alt="Express Delivery"
                className={classes.feature_item}
                />
              <Typography>Free shipping</Typography>
            </div>
            <div className={classes.feature}>
              <img
                src={ExchangeSvg}
                alt="Exchange"
                className={classes.feature_item}
                />
              <Typography>Free exchange</Typography>
            </div>
            <div className={classes.feature}>
              <img
                src={CashlessPayment}
                alt="Cashless Payment"
                className={classes.feature_item}
                />
              <Typography>Mode de paiement</Typography>
            </div>
        </Grid>
      </Grid>
      <Box className={classes.footer_copyright}>
        <div className={classes.scroll_up}>
          {window.innerWidth < 768 ? <ScrollUp /> : ""}
        </div>
        <Typography className={classes.copyright}>
          Tous les droits sont réservés pour <Link to="https://www.softcactus.ma/" target="_blank" style={ { textDecoration: "none", color: "black", letterSpacing: "1px" } } rel="noopener noreferrer">SOFT CACTUS 2025</Link>
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
