import classes from "./Reviews.module.css";
import { useState } from "react";
import Details from "./Details";
import Avis from "./Avis";
export default function Reviews() {
  const [activeTab, setActiveTab] = useState("reviews");
  const [isSelected, setIsSelected] = useState("Dérnier");
  const [visibleCards, setVisibleCards] = useState(6);

// const reviews = [
  //   {
  //     name: "Mohammed Lizati",
  //     stars: 5,
  //     message:
  //       "Excellente critique ! Le parfum a une odeur envoûtante qui dure longtemps. Je le recommande vivement à tous ceux qui aiment les fragrances de luxe.",
  //     posted: "07 août 2022",
  //   },
  //   {
  //     name: "Amine Bensid",
  //     stars: 4.5,
  //     message:
  //       "Un parfum agréable, mais je m’attendais à une odeur plus intense. En général, c’est un parfum unique qui mérite d’être essayé.",
  //     posted: "10 août 2022",
  //   },
  //   {
  //     name: "Sofia Khattab",
  //     stars: 3.5,
  //     message:
  //       "Le parfum est joli, mais je n’étais pas sûre qu’il corresponde à mes goûts personnels. Il pourrait être meilleur.",
  //     posted: "15 août 2022",
  //   },
  //   {
  //     name: "Youssef El Idrissi",
  //     stars: 5,
  //     message:
  //       "L’un des meilleurs parfums que j’aie jamais essayé ! Une fragrance fraîche et unique qui attire tous les regards.",
  //     posted: "20 août 2022",
  //   },
  //   {
  //     name: "Amina Rami",
  //     stars: 4,
  //     message:
  //       "Le parfum est magnifique, mais le prix est un peu élevé. Si vous recherchez quelque chose de luxe, c’est l’option idéale.",
  //     posted: "22 août 2022",
  //   },
  //   {
  //     name: "Omar Lahlou",
  //     stars: 5,
  //     message:
  //       "Ce parfum est incroyablement luxueux. On se sent comme si l’on portait l’élégance elle-même dans une bouteille.",
  //     posted: "25 août 2022",
  //   },
  //   {
  //     name: "Khalid Najah",
  //     stars: 3,
  //     message:
  //       "Le parfum est agréable, mais je ne suis pas totalement convaincu par la longévité. Il s’estompe assez rapidement.",
  //     posted: "28 août 2022",
  //   },
  //   {
  //     name: "Rachid Amrani",
  //     stars: 4,
  //     message:
  //       "Très bon parfum ! Il a une odeur légère et rafraîchissante, mais il pourrait être un peu plus fort.",
  //     posted: "30 août 2022",
  //   },
  //   {
  //     name: "Fatima Zahra",
  //     stars: 5,
  //     message:
  //       "Un parfum envoûtant qui me donne confiance en moi. La fragrance est élégante et parfaite pour les occasions spéciales.",
  //     posted: "02 septembre 2022",
  //   },
  //   {
  //     name: "Sara Benali",
  //     stars: 4,
  //     message:
  //       "J’ai adoré ce parfum ! Il est agréable et sophistiqué, mais je pense qu’il pourrait durer un peu plus longtemps.",
  //     posted: "05 septembre 2022",
  //   },
  //   {
  //     name: "Ali Dadi",
  //     stars: 5,
  //     message:
  //       "Un parfum que j’utilise tous les jours. Il est subtil et ne se fait pas remarquer, mais il laisse une belle impression.",
  //     posted: "08 septembre 2022",
  //   },
  //   {
  //     name: "Nadia Salim",
  //     stars: 2.5,
  //     message:
  //       "Un parfum élégant et subtil. J’apprécie la fraîcheur qu’il apporte, mais je m’attendais à quelque chose de plus prononcé.",
  //     posted: "10 septembre 2022",
  //   },
  //   {
  //     name: "Imane El Amrani",
  //     stars: 5,
  //     message:
  //       "Ce parfum est parfait pour toutes les saisons. Sa fragrance est douce, mais mémorable. Un choix incontournable.",
  //     posted: "12 septembre 2022",
  //   },
  //   {
  //     name: "Yassine Fadili",
  //     stars: 4,
  //     message:
  //       "Un très bon parfum, mais je trouve que le prix est un peu élevé par rapport à la quantité.",
  //     posted: "15 septembre 2022",
  //   },
  // ];
 

  return (
    <>
      <div className={classes.reviews_container}>
        <div className={classes.reviews_header}>
          <div
            className={`${classes.reviews_header_left} ${activeTab === "details" ? classes.active : ""
              }`}
            onClick={() => setActiveTab("details")}
          >
            Détails du product
          </div>
          <div
            className={`${classes.reviews_header_right} ${activeTab === "reviews" ? classes.active : ""
              }`}
            onClick={() => {
              setActiveTab("reviews");
              setVisibleCards(6)
             }}
          >
            Evaluation et avis
          </div>
          <div
            className={classes.line}
            style={{
              transform:
                activeTab === "details" ? "translateX(0%)" : "translateX(100%)",
            }}
          ></div>
        </div>
        <div className={classes.reviews_content}>
          {activeTab === "details" && (
            <Details />
          )}
          {activeTab === "reviews" && (
            <Avis isSelected={isSelected} visibleCards={visibleCards} setVisibleCards={setVisibleCards} />
          )}
        </div>
      </div>
    </>
  );
}
