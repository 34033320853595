import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";


import { getCookie } from "../functions";

const createApiClient = () => {
  const token = getCookie("token");
  return axios.create({
    baseURL: ApiRoutes.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
// const csrfToken = document.querySelector('meta[name="csrf-token"').getAttribute('content');
// const apiClient = axios.create({
//   baseURL: ApiRoutes.baseURL,
//   headers: {
//     "Content-Type": "application/json",
//     // "X-CSRF-TOKEN": csrfToken
//   },
// });


export const fetchReviews = async (product_id) => {
  const apiClient = createApiClient();
    try {
      const response = await apiClient.get(ApiRoutes.reviewByProduct(product_id));
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(
          error.response.data.message ||
            "Erreur lors de la récupération du reviews du Product"
        );
      } else if (error.request) {
        throw new Error("Aucune réponse du serveur");
      } else {
        throw new Error("Erreur dans la configuration de la requête");
      }
    }
  };

export const createReview = async (reviewData) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.addReview(), reviewData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || "Error while creating the review."
      );
    } else if (error.request) {
      throw new Error(
        "No response from the server. Please try again later."
      );
    } else {
      throw new Error("Error in the request configuration.");
    }
  }
};

