import React, { useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, Box, Drawer, ListItem } from "@mui/material";
import { validateEmail } from "../../utils/validation";
import classes from "./MobileAuthForm.module.css";
import Close from "./../../assets/icons/close.svg";
import AuthGoogle from "./../../assets/icons/auth_google.svg";
import AuthFacebook from "./../../assets/icons/auth_facebook.svg";
import CFacebook from "./../../assets/icons/auth/circledFacebook.svg";
import CInstagram from "./../../assets/icons/auth/circledInstagram.svg";
import CTiktok from "./../../assets/icons/auth/circledTikTok.svg";
import CWhatsapp from "./../../assets/icons/auth/CircledWhatsapp.svg";
import { useMutation } from "@tanstack/react-query";
import { submitLoginForm } from "../../utils/http/loginService";
import { setCookie } from "../../utils/functions";
import {
  checkAuthStatus,
  login as loginAction,
} from "../../redux/slices/authSlice";

export default function MobileLoginForm({ open }) {
  const handleGoogleLogin = () => {
    window.location.href = "https://crm.leschandelles.ma/api/auth/google";
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitLoginForm,
    onSuccess: (data) => {
      setCookie("token", data.token, 1);
      dispatch(loginAction());
      dispatch(checkAuthStatus());
      navigate("/");
    },
    onError: (error) => {
      console.error("Login failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
    } else {
      const formData = {
        email,
        password,
      };
      mutate(formData);
    }
  }

  const handleClose = () => {
    navigate("/");
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "100vw",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ListItem disablePadding>
          <div
            className={classes.close_container}
            onClick={handleClose}
            aria-label="Close">
            <div>
              <img src={Close} alt="Close" className={classes.close_icon} />
            </div>
            <div className={classes.close_text}>FERMER</div>
          </div>
        </ListItem>
        <div className={classes.form_container}>
          <h1 className={classes.title}>Se connecter.</h1>
          <p className={classes.redirect}>
            Vous n'avez pas encore de compte ?{" "}
            <Link className={classes.redirect_link} to="/register">
              Créer
            </Link>
          </p>
          {isError && (
            <Alert severity="error">
              {error.message || "Une erreur est survenue. Veuillez réessayer."}
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success">Vous avez correctement connecter.</Alert>
          )}
          <br />
          <Form onSubmit={handleSubmit}>
            <div className={classes.form_group}>
              <input
                type="email"
                name="email"
                placeholder="Entrez votre adresse e-mail"
                className={emailError ? classes.input_error : classes.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <span className={classes.error_message}>{emailError}</span>
              )}
            </div>
            <div className={classes.form_group}>
              <input
                type="password"
                name="password"
                placeholder="Entrez votre mot de passe."
                className={classes.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={`${classes.form_group} ${classes.remember_forgot}`}>
              <p className={classes.checkbox_container}>
                <input
                  className={classes.checkbox}
                  type="checkbox"
                  name="remember"
                  id="remember_me"
                />
                <label htmlFor="remember_me">Se souvenir de moi</label>
              </p>
              <p>
                <Link className={classes.redirect_link} to="/forgot-password">
                  Mot de passe oublié ?
                </Link>
              </p>
            </div>
            <div>
              <button type="submit" className={classes.submit_button}>
                {isPending ? "Connexion en cours..." : "Connexion"}
              </button>
            </div>
            <p className={classes.use_other_method}>
              Ou utiliser d'autres méthodes.
            </p>
            <div
              className={classes.social_login}
              onClick={handleGoogleLogin}
              style={{ cursor: "pointer" }}
            >
              <img src={AuthGoogle} alt="Google" />
              <p>Continuer avec Google</p>
            </div>
            {false && (
              <div className={classes.social_login}>
                <img src={AuthFacebook} alt="Facebook" />
                <p>Continuer avec Facebook</p>
              </div>
            )}
          </Form>
          <div className={classes.slogan_container}>
            <h1 className={classes.slogan}>Crafting light, Sharing delight</h1>
          </div>
          <div className={classes.social_links}>
            <Link
              to="https://facebook.com/people/les-chandelles/61553683991616/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CFacebook} alt="Facebook" />
            </Link>
            <Link
              to="https://www.instagram.com/les_chandelles_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CInstagram} alt="Instgarm" />
            </Link>
            <Link
              to="https://www.tiktok.com/@les_chandelles_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CTiktok} alt="Tiktok" />
            </Link>
            <Link
              to="https://wa.me/+212637222323"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CWhatsapp} alt="Whatsapp" />
            </Link>
          </div>
        </div>
      </Box>
    </Drawer>
  );
}
