import React from "react";
import classes from "./Title.module.css";

function Title({ title }) {
  return (
    <div className={classes.title_container}>
      <h2 className={classes.title}>
        {title}
        <span className={classes.colored_text} aria-hidden="true">
          {title}
        </span>
      </h2>
    </div>
  );
}

export default Title;
