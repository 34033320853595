import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";
import { getCookie } from "../functions";


const getCSRFToken = () => {
  return document.querySelector('meta[name="csrf-token"]').getAttribute("content");
};
const createApiClient = () => {
  const token = getCookie("token");
  const csrfToken = getCSRFToken();
  return axios.create({
    baseURL: ApiRoutes.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "X-CSRF-TOKEN": csrfToken,
    },
  });
};

export const fetchCart = async () => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.get(ApiRoutes.carts());
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la récupération du panier"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};

export const addLocalStoreToCart = async (items) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.addLocalItemsToDb(), {
      items,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la synchronisation du panier."
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur.");
    } else {
      throw new Error("Erreur dans la configuration de la requête.");
    }
  }
};

export const addToCart = async (newItem) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.carts(), newItem);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de l'ajout du produit au panier"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};

export const removeFromCart = async (cartItemId) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.delete(ApiRoutes.removefromcart(cartItemId));
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la suppression du produit du panier"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};
