import React from "react";
import { Grid } from "@mui/material";
import classes from "./FrequentlyAskedQuestions.module.css";
import FaqImg from "./../../assets/images/faq/image-2.png";
import Title from "./../ui/Title";
import ControlledAccordions from "./ControlledAccordions";

const ProjectQuestions = ({ faqs }) => {
  return (
    <section style={{ marginTop: "100px" }}>
      <Title title="QUESTIONS LIÉES AU PROJET" />
      <div className={classes.faq_container}>
          <div className={classes.faq_image_container} style={{ justifyContent: "flex-start" }}>
            <img className={classes.faq_image} src={FaqImg} alt="Faq" />
          </div>
          <div className={classes.faq_content}>
            <ControlledAccordions faqs={faqs} />
          </div>
      </div>
    </section>
  );
};

export default ProjectQuestions;
