import React from "react";
import Title from "../../ui/Title";
import classes from "./Store.module.css";
import Img1 from "./../../../assets/images/products/p4.jpeg";
import Img2 from "./../../../assets/images/products/p2.jpeg";
import Img3 from "./../../../assets/images/products/p3.jpeg";
import Img4 from "./../../../assets/images/products/p4.jpeg";

function Store() {
  return (
    <section className={classes.store_container}>
      <Title title="NOTRE MAGASIN" />
      <div className={classes.store_content}>
        <div className={classes.box}>
          <div className={classes.store_images}>
            <div className={classes.first_container}>
              <label className={classes.title_image}>Chocolate Candle</label>
              <img className={classes.image} src={Img1} alt="Chocolate Candle" />
            </div>
            <div className={classes.second_container}>
              <label className={classes.title_image}>Ylang Ylang</label>
              <img className={classes.image} src={Img2} alt="Ylang Ylang Candle" />
            </div>
            <div className={classes.third_container}>
              <label className={classes.title_image}>Vanilla Paris</label>
              <img className={classes.image} src={Img3} alt="Vanilla Paris Candle" />
            </div>
            <div className={classes.fourth_container}>
              <label className={classes.title_image}>Caramille Parfum</label>
              <img className={classes.image} src={Img4} alt="Caramille Parfum Candle" />
            </div>
          </div>
        </div>
        <div className={classes.store_text_content}>
          <div className={classes.circle}></div>
          <h1 className={classes.bottom_title}>LES CHANDELLES</h1>
          <p className={classes.bottom_text}>
            Centre d'aide, Litiges et rapports, Protection des consommateurs,
            Régulation de la DPI, Informations réglementées, Conformité à
            l'intégrité.
          </p>
          <p className={classes.bottom_text}>
            Tous les produits populaires, Promotions, Prix bas, Excellent
            rapport qualité-prix, Commentaires, Blog, Portail vendeur,
            BLACK FRIDAY, Assistant.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Store;
