import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Grid,
  Box,
  Pagination,
  PaginationItem,
  CircularProgress,
  Alert,
} from "@mui/material";
import { fetchProducts } from "../../utils/http/productService";
import classes from "./Products.module.css";
import Product from "./../ui/Product";
import { API_BASE_URL } from "../../apis/ApiRoutes";

function Products() {
  const { id } = useParams();
  const idAsNumber = parseInt(id, 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    window.innerWidth < 1200 ? 12 : 16
  );

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    staleTime: 5000,
    select: (data) => {
      return data.products || [];
    },
    onSuccess: () => {
      setCurrentPage(1);
    },
  });

  const filteredProducts = useMemo(() => {
    return data
      ? data.filter( (product) =>
          product.perfumes.some(
            (perfume) => parseInt(perfume.id, 10) === idAsNumber
          )
        )
      : [];
  }, [data, idAsNumber]);

  const totalPages = filteredProducts.length
    ? Math.ceil(filteredProducts.length / itemsPerPage)
    : 0;

  const getCurrentProducts = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredProducts.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = window.innerWidth >= 992 ? 16 : 12;
      setItemsPerPage(newItemsPerPage);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (filteredProducts.length > 0) {
      const maxPage = Math.ceil(filteredProducts.length / itemsPerPage);
      if (currentPage > maxPage) {
        setCurrentPage(maxPage);
      }
    }
  }, [itemsPerPage, filteredProducts, currentPage]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {error.message || "Erreur lors de la récupération des produits."}
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{ flexGrow: 1, padding: 2 }}
      className={classes.products_container}
    >
      <Grid container spacing={3}>
        {getCurrentProducts().map((product) => ( 
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={product.id}>
            <Product
              productId={product.id}
              // imgSrc={`${API_BASE_URL}/${product.images[0].image_url}`}
              imgSrc={product.images[0].image_url}
              productName={product.name}
              productPrice={product.price}
              productReview={product.review}
              />
          </Grid>
        ))}
      </Grid>
      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant={"outlined"}
            shape={"rounded"}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#874493",
                borderColor: "#874493",
                fontFamily: "var(--chndl-primary-font-family)",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#874493",
                color: "#ffffff",
                fontFamily: "var(--chndl-primary-font-family)",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                slots={{
                  previous: (props) => (
                    <span style={{ width: "100px" }} {...props}>
                      Précédent
                    </span>
                  ),
                  next: (props) => (
                    <span style={{ width: "100px" }} {...props}>
                      Suivant
                    </span>
                  ),
                }}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
}

export default Products;
