import React from "react";
import ProductDetails from "./../components/product-details/ProductDetails";
import Reviews from "../components/product-details/Reviews/Reviews";

function ProductDetailsPage() {
  return (
    <>
      <ProductDetails />
      <Reviews />
    </>
  );
}

export default ProductDetailsPage;
