import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Grid,
  Breadcrumbs,
  Divider,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ColoredCircle from "../ui/ColoredCircle";
import classes from "./ProductDetails.module.css";
import SelectArrow from "./../../assets/icons/SelectArrow.svg";
import ImageCarousel from "./ImageCarousel";
import {
  addItemToCartAsync,
  fetchCartAsync,
} from "../../redux/slices/cartSlice";
import { fetchPerfumes } from "../../utils/http/perfumeService";
import { fetchProduct } from "../../utils/http/productService";
import {
  addFavoriteAsync,
  loadFavorites,
  removeFavoriteAsync,
} from "../../redux/slices/favoritesSlice";

function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [favoriteisLoading, setFavoriteisLoading] = useState(false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPerfume, setSelectedPerfume] = useState({
    id: null,
    name: "Nos parfums",
  });
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    data: perfumes,
    isLoading: loadingPerfumes,
    isError: errorPerfumes,
    error: perfumesError,
  } = useQuery({
    queryKey: ["perfumes"],
    queryFn: fetchPerfumes,
    staleTime: 5000,
    select: (data) => data.perfumes || [],
  });

  const {
    data: product,
    isLoading: loadingProduct,
    isError: errorProduct,
    error: productError,
  } = useQuery({
    queryKey: ["product", id],
    queryFn: () => fetchProduct(id),
    staleTime: 5000,
    select: (data) => data.product || [],
  });

  const isFavorite = product
    ? favorites.some((item) => Number(item.product_id) === Number(product.id))
    : false;

  useEffect(() => {
    if (product?.images?.length) {
      setSelectedImage(product.images[0].image_url);
    }
    let initialActiveSize= parseInt(product?.price_small)!==0 
                ? "s" 
                : parseInt(product?.price_meduim)!==0 
                ? "m" 
                : "l"
    setSelectedSize(initialActiveSize)
  }, [product]);

  const filteredPerfumes =
    product &&
    product.perfumes &&
    product.perfumes.length > 0 &&
    perfumes.length > 0
      ? perfumes.filter((perfume) =>
          product.perfumes.some(
            (productPerfume) => productPerfume.id === perfume.id
          )
        )
      : [];

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleColorChange = (color) => setSelectedColor(color);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = (perfume) => {
    setAnchorEl(null);
    if (perfume) {
      const selectedPerfumeObj = perfumes.find((p) => p.id === perfume);
      setSelectedPerfume({
        id: selectedPerfumeObj.id,
        name: selectedPerfumeObj.name,
      });
    }
  };

  const handleFavoriteToggle = async () => {
    if (isLoggedIn) {
      setFavoriteisLoading(true);
      try {
        if (isFavorite) {
          await dispatch(removeFavoriteAsync(product.id));
        } else {
          await dispatch(addFavoriteAsync(product.id));
        }
        await dispatch(loadFavorites());
      } finally {
        setFavoriteisLoading(false);
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddToCart = () => {
    const price =
      selectedSize === "s"
        ? product.price_small
        : selectedSize === "m"
        ? product.price_medium
        : product.price_large;

    if (
      !product?.name ||
      selectedPerfume.id === null ||
      !price ||
      selectedQuantity < 1 ||
      !selectedSize ||
      !selectedColor
    ) {
      setErrorMessage("Veuillez ajouter toutes les informations nécessaires.");
      return;
    }

    const newItem = {
      product_id: product.id,
      perfume_id: selectedPerfume.id,
      quantity: selectedQuantity,
      size: selectedSize,
      color_id: selectedColor,
    };

    dispatch(addItemToCartAsync({ newItem, isAuthenticated: isLoggedIn }));
    dispatch(fetchCartAsync());
  };

  const IsExistTaille = (priceTaille) => {
    if (parseInt(priceTaille) === 0) return classes.hide;
    return "";
  };  

  if (loadingPerfumes || loadingProduct) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (errorPerfumes || errorProduct) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert
          severity="error"
          style={{ minWidth: "50vw" }}
          onClose={() => setErrorMessage(null)}
        >
          {perfumesError?.message ||
            productError?.message ||
            "Erreur lors de la récupération des données."}
        </Alert>
      </Box>
    );
  }

  return (
    <section className={classes.product_details_container}>
      <div className={classes.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          color="#EA6577"
        >
          <Link to="/" key="home">
            Accueil
          </Link>
          <Link to="#" key="products">
            Produits
          </Link>
          <Link
            key="product-details"
            className={classes.active}
            to={`/product-details/${id}`}
          >
            Détails du produit
          </Link>
        </Breadcrumbs>
      </div>
      <Grid container sx={{ alignItems:"center" }}>
        <Grid item xs={12} md={6} className={classes.product_img_container}>
          {!isSmallScreen ? (
            <>
              <div className={classes.big_picture}>
                <img src={selectedImage} alt="product" />
              </div>
              <div className={classes.small_pictures}>
                {product.images.map((img, index) => (
                  <div
                    key={index}
                    className={`${classes.small_picture} ${
                      selectedImage === img.image_url
                        ? classes.selected_img
                        : ""
                    }`}
                    onClick={() => setSelectedImage(img.image_url)}
                  >
                    <img src={img.image_url} alt="product" />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div style={{ margin: "-1rem" }}>
              <ImageCarousel images={product.images} />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.product_content_container}>
          <div>
            <h1 className={classes.product_name}>
              {product?.name || "Produit"}
            </h1>
            <p className={classes.product_description}>{product.description}</p>
            <div className={classes.stars}>
              <StarRateRoundedIcon className={classes.star} />
              <StarRateRoundedIcon className={classes.star} />
              <StarRateRoundedIcon className={classes.star} />
              <StarHalfRoundedIcon className={classes.star} />
              <StarBorderRoundedIcon className={classes.star} />
            </div>
            <div className={classes.perfumes}>
              <label htmlFor="">Parfum</label>
              <div
                className={classes.perfumes_select}
                aria-controls="perfumes-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <p>{selectedPerfume.name}</p>
                <div className={classes.select_arrow_perfumes_container}>
                  <img
                    src={SelectArrow}
                    className={classes.select_arrow_icon}
                    alt="Select Arrow"
                  />
                </div>
              </div>
              <Menu
                id="perfumes-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                  style: {
                    maxHeight: "200px",
                    width: anchorEl ? anchorEl.clientWidth : undefined,
                  },
                }}
              >
                {filteredPerfumes.map((perfume) => (
                  <MenuItem
                    key={perfume.id}
                    selected={perfume.id === selectedPerfume.id}
                    onClick={() => handleClose(perfume.id)}
                  >
                    {perfume.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className={classes.sizes}>
              <div className={classes.quantity_content}>
                <label htmlFor="">Quantité</label>
                <input
                  type="number"
                  min={1}
                  max={999}
                  defaultValue={1}
                  onChange={(e) => setSelectedQuantity(Number(e.target.value))}
                />
              </div>
              <div className={classes.size_content}>
                <label htmlFor="">Size</label>
                <div className={classes.btns_grp}>
                  <button
                    className={`${classes.size_btn} ${
                      selectedSize === "s" ? classes.active : ""
                    } ${IsExistTaille(product.price_small)}`}
                    onClick={() => setSelectedSize("s")}
                  >
                    Petit
                  </button>
                  <button
                    className={`${classes.size_btn} ${
                      selectedSize === "m" ? classes.active : ""
                    } ${IsExistTaille(product.price_medium)}`}
                    onClick={() => setSelectedSize("m")}
                  >
                    Moyen
                  </button>
                  <button
                    className={`${classes.size_btn} ${
                      selectedSize === "l" ? classes.active : ""
                    } ${IsExistTaille(product.price_large)}`}
                    onClick={() => setSelectedSize("l")}
                  >
                    Grand
                  </button>
                </div>
              </div>
              <div className={classes.favorite} onClick={handleFavoriteToggle}>
                {favoriteisLoading ? (
                  <CircularProgress size={24} style={{ color: "red" }} />
                ) : isFavorite ? (
                  <FavoriteIcon className={classes.favorite_icon} style={{ color: "red" }} />
                ) : (
                  <FavoriteBorderIcon className={classes.favorite_icon} style={{ color: "black" }}/>
                )}
              </div>
            </div>
            <div className={classes.colors_container}>
              <label htmlFor="">Couleurs</label>
              <div className={classes.colors}>
                {product.colors.map((color, index) => (
                  <ColoredCircle
                    key={index}
                    bgColor={color.hex_value}
                    isActive={selectedColor === color.id}
                    onClick={() => handleColorChange(color.id)}
                  />
                ))}
              </div>
            </div>
            <Divider className={classes.divider} />
          </div>
          <div>
            <div className={classes.price}>
              <p className={classes.text}>PRIX :</p>
              <p className={classes.value}>
                {selectedSize === "s"
                  ? product.price_small
                  : selectedSize === "m"
                  ? product.price_medium
                  : product.price_large}{" "}
                DH
              </p>
            </div>
            <div className={classes.cart_action}>
              {errorMessage && (
                <Alert
                  severity="error"
                  onClose={() => setErrorMessage(null)}
                  style={{ marginBottom: "1rem" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <button
                className={classes.add_to_cart_btn}
                onClick={handleAddToCart}
              >
                Ajouter au panier
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}

export default ProductDetails;
