import React from "react";
import Telephone from "./../../assets/icons/contact/tel.svg";
import Envlop from "./../../assets/icons/contact/envelope.svg";
import Insta from "./../../assets/icons/contact/instagram.svg";
import Location from "./../../assets/icons/contact/location.svg";
import CFacebook from "./../../assets/icons/contact/c-facebook.svg";
import CInstagram from "./../../assets/icons/contact/c-instagram.svg";
import CTiktok from "./../../assets/icons/contact/c-tiktok.svg";
import CWhatsapp from "./../../assets/icons/contact/c-whatsapp.svg";
import CLocation from "./../../assets/icons/contact/c-location.svg";
import CEnvelope from "./../../assets/icons/contact/c-envelope.svg";
import classes from "./ContactInfo.module.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

function ContactInfo() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.contact_info}>
      <h1 className={classes.contact_info_title}>Contact Informations</h1>
      {!isSmallScreen ? (
        <div className={classes.contact_items_container}>
          <div className={classes.contact_item}>
            <p>
              <img src={Telephone} alt="telephone" />
            </p>
            <p>
              <Link
                to="tel:0637222232"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.contact_link}
              >
                06 37 - 222 - 232
              </Link>
            </p>
          </div>
          <div className={classes.contact_item}>
            <p>
              <img src={Envlop} alt="envelope" />
            </p>
            <p>
              <Link
                to="mailto:contact@leschandelles.ma"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.contact_link}
              >
                contact@leschandelles.ma
              </Link>
            </p>
          </div>
          <div className={classes.contact_item}>
            <p>
              <img src={Insta} alt="instagram" />
            </p>
            <p>
              <Link
                to="https://www.instagram.com/les_chandelles_/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.contact_link}
              >
                leschandelles
              </Link>
            </p>
          </div>
          <div className={classes.contact_item}>
            <p>
              <img src={Location} alt="location" />
            </p>
            <p>
              5 éme étage appartement n° 38 rue ibn rouched imb el mouhandissine
              oujda
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.social_links}>
          <Link
            to="https://facebook.com/people/les-chandelles/61553683991616/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CFacebook} alt="circle facebook" />
          </Link>
          <Link
            to="https://www.instagram.com/les_chandelles_/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CInstagram} alt="circle instagram" />
          </Link>
          <Link
            to="https://www.tiktok.com/@les_chandelles_"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CTiktok} alt="circle tiktok" />
          </Link>
          <Link
            to="https://wa.me/+212637222323"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CWhatsapp} alt="circle whatsapp" />
          </Link>
          <Link
            to="https://www.google.com/maps"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CLocation} alt="circle location" />
          </Link>
          <Link
            to="mailto:contact@leschandelles.ma"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social_link}
          >
            <img src={CEnvelope} alt="circle envelope" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default ContactInfo;
