import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Alert,
  CircularProgress,
} from "@mui/material";
import { fetchPerfumes } from "../../utils/http/perfumeService";
import Title from "./../ui/Title";
import Product from "../ui/Product";
import FavoriteMobileProduct from "./FavoriteMobileProduct";
import SubscribeNewsletter from "../common/newsletter/SubscribeNewsletter";
import classes from "./Favorites.module.css";
import SelectArrow from "./../../assets/icons/SelectArrow.svg";
import {
  loadFavorites,
  removeFavoriteAsync,
} from "../../redux/slices/favoritesSlice";

function Favorites() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPerfume, setSelectedPerfume] = useState("Nos parfum");
  const [itemsToShow, setItemsToShow] = useState(isSmallScreen ? 6 : 12);

  const favorites = useSelector((state) => state.favorites);

  const {
    data: perfumesData = { perfumes: [] },
    isLoading: perfumesLoading,
    isError: perfumesError,
    error: perfumesErrorMessage,
  } = useQuery({
    queryKey: ["perfumes"],
    queryFn: fetchPerfumes,
    staleTime: 5000,
  });

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadFavorites());
    }
  }, [isLoggedIn, dispatch]);

  const handleShowMore = () => {
    if (isSmallScreen) {
      setItemsToShow((prev) => prev + 6);
    } else {
      setItemsToShow((prev) => prev + 12);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (perfume) => {
    setAnchorEl(null);
    if (perfume) {
      setSelectedPerfume(perfume);
    }
  };

  const handleRemoveFavorite = (productId) => {
    dispatch(removeFavoriteAsync(productId));
  };

  const filteredFavorites = favorites.filter((favorite) => {
    if (selectedPerfume === "Nos parfum") {
      return true;
    }

    const selectedPerfumeObj = perfumesData.perfumes.find(
      (perfume) => perfume.name === selectedPerfume
    );
    return (
      selectedPerfumeObj &&
      favorite.product.perfumes.some(
        (perfume) => perfume.id === selectedPerfumeObj.id
      )
    );
  });

  if (perfumesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (perfumesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {perfumesErrorMessage?.message ||
            "Erreur lors de la récupération des produits."}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Title title="Mon Préféré" />
      {!isSmallScreen ? (
        <Box className={classes.favorites_container}>
          <div className={classes.perfumes}>
            <div
              className={classes.perfumes_select}
              aria-controls="perfumes-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <p>{selectedPerfume}</p>
              <div className={classes.select_arrow_perfumes_container}>
                <img
                  src={SelectArrow}
                  className={classes.select_arrow_icon}
                  alt="Select Arrow"
                />
              </div>
            </div>
            <Menu
              id="perfumes-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => handleClose()}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              PaperProps={{
                style: {
                  maxHeight: "200px",
                  width: anchorEl ? anchorEl.clientWidth : undefined,
                },
              }}
            >
              <MenuItem onClick={() => handleClose("Nos parfum")}>
                Nos parfum
              </MenuItem>
              {perfumesData.perfumes.map((perfume) => (
                <MenuItem
                  key={perfume.id}
                  selected={perfume.name === selectedPerfume}
                  onClick={() => handleClose(perfume.name)}
                >
                  {perfume.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <Grid container spacing={3}>
            {filteredFavorites.length > 0 ? (
              filteredFavorites.slice(0, itemsToShow).map((favorite) => (
                <Grid item xs={6} sm={4} md={3} lg={3} key={favorite.id}>
                  <Product
                    productId={favorite.product.id}
                    imgSrc={favorite.product.images[0].image_url}
                    productName={favorite.product.name}
                    productPrice={favorite.product.price}
                    productDescription={favorite.product.productDescription}
                    productColors={favorite.product.colors.map(
                      (color) => color.hex_value
                    )}
                    onRemove={() => handleRemoveFavorite(favorite.product.id)}
                  />
                </Grid>
              ))
            ) : (
              <Box
                width="100%"
                height="250px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Alert severity="info">
                  {isLoggedIn
                    ? "Vous n'avez pas encore ajoutez de favoris"
                    : "Connectez-vous pour ajouter des favoris"}
                </Alert>
              </Box>
            )}
          </Grid>
          {itemsToShow < filteredFavorites.length && (
            <button className={classes.show_more_btn} onClick={handleShowMore}>
              Show More
            </button>
          )}
        </Box>
      ) : (
        <>
        <div className={classes.perfumes}>
          <div
            className={classes.perfumes_select}
            aria-controls="perfumes-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <p>{selectedPerfume}</p>
            <div className={classes.select_arrow_perfumes_container}>
              <img
                src={SelectArrow}
                className={classes.select_arrow_icon}
                alt="Select Arrow"
              />
            </div>
          </div>
          <Menu
            id="perfumes-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            PaperProps={{
              style: {
                maxHeight: "200px",
                width: anchorEl ? anchorEl.clientWidth : undefined,
              },
            }}
          >
            <MenuItem onClick={() => handleClose("Nos parfum")}>
              Nos parfum
            </MenuItem>
            {perfumesData.perfumes.map((perfume) => (
              <MenuItem
                key={perfume.id}
                selected={perfume.name === selectedPerfume}
                onClick={() => handleClose(perfume.name)}
              >
                {perfume.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.favorite_mobile_header}>
              <div className={classes.header_content}>
                <span className={classes.header_article}>Article</span>
                <span className={classes.header_total}>Total</span>
              </div>
              <div className={classes.header_divider}></div>
            </div>
          </Grid>
          {filteredFavorites.length > 0 ? (
            filteredFavorites.slice(0, itemsToShow).map((favorite) => (
              <Grid item xs={12} key={favorite.id}>
                <FavoriteMobileProduct
                  productId={favorite.product.id}
                  imgSrc={favorite.product.images[0].image_url}
                  productName={favorite.product.name}
                  productPrice={favorite.product.price}
                  productColors={favorite.product.colors.map(
                    (color) => color.hex_value
                  )}
                  onRemove={() => handleRemoveFavorite(favorite.product.id)}
                />
              </Grid>
            ))
          ) : (
            <Box
              width="100%"
              height="250px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Alert severity="info">
                {isLoggedIn
                  ? "Vous n'avez pas encore ajoutez de favoris"
                  : "Connectez-vous pour ajouter des favoris"}
              </Alert>
            </Box>
          )}
        </Grid>
        {itemsToShow < filteredFavorites.length && (
          <button className={classes.show_more_btn} onClick={handleShowMore}>
            Show More
          </button>
        )}
        </>
      )}
      <SubscribeNewsletter />
    </>
  );
}

export default Favorites;
