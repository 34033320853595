import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Alert,
  Box,
  CircularProgress,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fetchPerfumes } from "../../../utils/http/perfumeService";
import { setSelectedPerfume } from "../../../redux/slices/perfumeSlice";
import Title from "../../ui/Title";
import classes from "./OurPerfumes.module.css";

function OurPerfumes() {
  const heights = [
    330, 250, 330, 250, 250, 250, 330, 330, 330, 250, 330, 250, 250, 250, 330,
    330,
  ];

  const mobileHeights = [250, 200, 200, 200, 250, 200, 200, 200];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumToSmall = useMediaQuery('(min-width:600px) and (max-width:1023px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePerfumeSelect = (perfume) => {
    if (perfume) {
      dispatch(setSelectedPerfume(perfume.name));
      navigate(`/perfumes/${perfume.id}`);
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["perfumes"],
    queryFn: fetchPerfumes,
    staleTime: 5000,
    select: (data) => data.perfumes || [],
  });

  const [visibleCount, setVisibleCount] = useState(
    isSmallScreen ? mobileHeights.length : 16
  );

  useEffect(() => {
    setVisibleCount(isSmallScreen ? mobileHeights.length : 16);
  }, [isSmallScreen, mobileHeights.length]);

  const loadMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 4, data?.length || 0));
  };

  let content;

  if (isLoading) {
    content = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    content = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {error.message || "Erreur lors de la récupération des collections."}
        </Alert>
      </Box>
    );
  }

  if (data) {
    content = (
      <div className={classes.perfumes_container}>
        <Box sx={{ width: "100%", height: "auto" }}>
          {isMediumToSmall && (
            <ImageList
            variant="masonry"
            cols={3}
            gap={24}
            className={classes.desktopImageList}
          >
            {data.slice(0, visibleCount).map((item, index) => (
              <ImageListItem
                key={item.id}
                style={{ height: heights[index] || 250, overflow: "hidden" }}
                className={classes.image_list_item}
                onClick={() => handlePerfumeSelect(item)} // Appeler handlePerfumeSelect au clic
              >
                <img
                  src={item.image_url}
                  className={classes.image}
                  alt={item.name}
                  loading="lazy"
                />
                <span className={classes.image_title}>{item.name}</span>
              </ImageListItem>
            ))}
          </ImageList>
          )}
          {!isSmallScreen && !isMediumToSmall && (
            <ImageList
              variant="masonry"
              cols={4}
              gap={24}
              className={classes.desktopImageList}
            >
              {data.slice(0, visibleCount).map((item, index) => (
                <ImageListItem
                  key={item.id}
                  style={{ height: heights[index] || 250, overflow: "hidden" }}
                  className={classes.image_list_item}
                  onClick={() => handlePerfumeSelect(item)} // Appeler handlePerfumeSelect au clic
                >
                  <img
                    src={item.image_url}
                    className={classes.image}
                    alt={item.name}
                    loading="lazy"
                  />
                  <span className={classes.image_title}>{item.name}</span>
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {isSmallScreen && (
            <ImageList
              variant="masonry"
              cols={2}
              gap={8}
              className={classes.mobileImageList}
            >
              {data.slice(0, visibleCount).map((item, index) => (
                <ImageListItem
                  key={item.id}
                  style={{
                    height: mobileHeights[index] || 200,
                    overflow: "hidden",
                  }}
                  className={classes.image_list_item}
                  onClick={() => handlePerfumeSelect(item)} // Appeler handlePerfumeSelect au clic
                >
                  <img
                    src={item.image_url}
                    className={classes.image}
                    alt={item.name}
                    loading="lazy"
                  />
                  <span className={classes.image_title}>{item.name}</span>
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Box>
        {visibleCount < data.length && (
          <button className={classes.show_more_btn} onClick={loadMore}>
            Voir plus
          </button>
        )}
      </div>
    );
  }

  return (
    <section className={classes.our_perfumes_container}>
      <Title title="NOS PARFUMS" />
      {content}
    </section>
  );
}

export default OurPerfumes;
