import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./FrequentlyAskedQuestions.module.css";
import { useState } from "react";

export default function ControlledAccordions({ faqs }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.accordion_container}>
      {faqs.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon 
                className={`${classes.expandIcon} ${expanded === item.id ? classes.rotated : ''}`}
              />
            }
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
            className={classes.accordion_summary}
          >
            <Typography
              className={`${classes.questions} ${
                expanded === item.id ? classes.expanded : ""
              }`}
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordion_details}>
            <Typography className={classes.answer}>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
